<template>
    <div class="news-teams-item">
        <div class="block mb-16">
            <div class="block-title">
                <div class="dot" />
                {{ $t('news.landing.title[2]') }}
            </div>
            <Item
                :data="item"
                :label-open="true"
                :label-router="'NewsTeams'"
            />
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import Item from '@/components/Item.vue';

export default {
    components: {
        Item,
    },
    props: {

    },
    data() {
        return {
            item: {},
        };
    },
    watch: {
        $route() {
            this.View();
        },
    },
    mounted() {
        this.View();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async View() {
            try {
                const res = await API.Article.PublicFetchResource(this.$route.params.id, {
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.item = res.data;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">

</style>
